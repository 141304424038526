Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";

exports.httpGetMethod = "GET";
exports.httpLandingPageRoute = "bx_block_landing/landings";
exports.reviewRoute = "bx_block_reviews/reviews";

exports.dishUpAlt = "Dish UP";
exports.googlePlayAlt = "Google Play";
exports.appleStoreAlt = "App Store";
exports.signUpButton = "Signup now";
exports.playerTitle = "Youtube player";
exports.emailIcon = "Email Icon";
exports.locationIcon = "Location"
exports.ratingData = [
  {
    ratingType: 5,
    ratingBarValue: 30,
    ratingCounts: 20
  },
  {
    ratingType: 4,
    ratingBarValue: 10,
    ratingCounts: 2
  },
  {
    ratingType: 3,
    ratingBarValue: 0,
    ratingCounts: 0
  },
  {
    ratingType: 2,
    ratingBarValue: 5,
    ratingCounts: 1
  },
  {
    ratingType: 1,
    ratingBarValue: 0,
    ratingCounts: 0
  }
];
exports.show_main_header = true;
exports.show_sub_header = true;
exports.show_signup_button = true;
exports.show_google_play_button = true;
exports.show_apple_store_button = true;
exports.show_youtube_video = true;
exports.show_hotel_card = true;
exports.show_rating_card = true;
exports.sign_up_url = "www.google.com";

// Customizable Area End