export const imgNavLogoDishup = require("../assets/nav_logo_dish_up.svg");
export const imgSkinBadgeGooglePlay = require("../assets/skip_badge_google_play.svg");
export const imgSkinBadgeAppStore = require("../assets/skip_badge_app_store.svg");
export const imgBackground = require("../assets/bg_image.svg");
export const imgBackgroundMobile = require("../assets/background_image_mobile.svg");
export const forkIcon = require("../assets/skip_fluent_food-16-filled.svg");
export const emailIcon = require("../assets/email.png")
export const locationIcon = require("../assets/location.png")


