import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  AppBar,
  Toolbar,
  Grid,
  Card,
  CardContent,
  LinearProgress,
  withStyles,
  Container,
  withWidth,
  isWidthUp,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  forkIcon,
  imgBackground,
  imgBackgroundMobile,
  imgNavLogoDishup,
  imgSkinBadgeAppStore,
  imgSkinBadgeGooglePlay,
  emailIcon,
  locationIcon,
} from "./assets";
import StarIcon from "@material-ui/icons/Star";
import { Rating } from "@material-ui/lab";
import CallMadeIcon from "@material-ui/icons/CallMade";
import "./LandingPage.web.css";
import { Link } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#F3E344",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        colorInherit: "#F3E344",
        textTransform: "none" as const,
      },
    },
  },
});
// Customizable Area End

// Customizable Area Start
import LandingPageController, {
  Props,
  configJSON,
} from "./LandingPageController";
// Customizable Area End

export class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, width }: any = this.props;
    const {
      youtube_url,
      title,
      description,
      hide_title,
      hide_description,
      hide_youtube_url,
      hide_sign_up,
      hide_google_store,
      hide_apple_store,
      hide_navbar_google_store,
      hide_navbar_apple_store,
      hide_review_rating,
      hide_experience,
      hide_instagram_url,
      hide_facebook_url,
      hide_tiktok_url,
      hide_email,
      hide_address,
      meal_type,
      label,
      order_date,
      type_of_food,
      rating,
      rating_count,
      order_time,
      review,
      price,
      currency,
      five_star_rating_count,
      four_star_rating_count,
      three_star_rating_count,
      two_star_rating_count,
      one_star_rating_count,
      email,
      address,
    } = this.state.data;
    const isSmallScreen = isWidthUp("sm", width);

    let apiDate = new Date(order_date);
    let daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let formatted_order_date = `${
      daysOfWeek[apiDate.getDay()]
    }, ${apiDate.getDate()} ${
      months[apiDate.getMonth()]
    } ${apiDate.getFullYear()}`;

    let ratingData = [
      {
        ratingType: 5,
        ratingBarValue: (five_star_rating_count / review) * 100,
        ratingCounts: five_star_rating_count,
      },
      {
        ratingType: 4,
        ratingBarValue: (four_star_rating_count / review) * 100,
        ratingCounts: four_star_rating_count,
      },
      {
        ratingType: 3,
        ratingBarValue: (three_star_rating_count / review) * 100,
        ratingCounts: three_star_rating_count,
      },
      {
        ratingType: 2,
        ratingBarValue: (two_star_rating_count / review) * 100,
        ratingCounts: two_star_rating_count,
      },
      {
        ratingType: 1,
        ratingBarValue: (one_star_rating_count / review) * 100,
        ratingCounts: one_star_rating_count,
      },
    ];

    let ratingDetail = `${rating} (${rating_count})`;

    let avRating = this.state.avarageRating;
    let completeRating = this.state.rating;

    let order_time_object = new Date(order_time);
    let order_time_formated = order_time_object.toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    function experience_currency() {
      let curr = currency && currency.toLowerCase();

      const currencyObj: any = {
        uk: "£",
        us: "$",
        fr: "€",
      };

      return currencyObj[curr];
    }

    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <div style={{ overflow: "auto" }}>
          <AppBar position="static">
            <Toolbar className={classes.toolBarHeightStyle}>
              <Box>
                <img
                  className={classes.dishUpLogoStyle}
                  src={imgNavLogoDishup}
                  alt={configJSON.dishUpAlt}
                  data-test-id={"dishup-logo"}
                />
              </Box>
              <Box
                sx={{ marginRight: "60px" }}
                className={classes.showNhideAppStore}
              >
                <Button
                  className={classes.downloadGoogleNavBarStyle}
                  onClick={() => this.clickGooglePlayButton()}
                  data-test-id={"play-store-button-navbar"}
                  style={{
                    display: !hide_navbar_google_store ? " inline" : "none",
                  }}
                >
                  <img
                    className={classes.googlePlayLogoBodyStyle}
                    src={imgSkinBadgeGooglePlay}
                    alt={configJSON.googlePlayAlt}
                  />
                </Button>
                <Button
                  className={classes.downloadButtonStyles}
                  onClick={() => this.clickAppleStoreButton()}
                  data-test-id={"apple-store-button-navbar"}
                  style={{
                    display: !hide_navbar_apple_store ? " inline" : "none",
                  }}
                >
                  <img
                    className={classes.appStoreLogoBodyStyle}
                    src={imgSkinBadgeAppStore}
                    alt={configJSON.appleStoreAlt}
                  />
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
          <div className={classes.mainContainer}>
            <div className={classes.parentDiv}>
              <Container className={classes.containerWrapper}>
                <div className={classes.childDiv}>
                  {!hide_title && title && (
                    <div className={classes.contentWrapper}>
                      <Typography
                        className={classes.mainHeading}
                        data-test-id={"main-heading"}
                      >
                        {title.trim()}
                      </Typography>
                    </div>
                  )}
                  {!hide_description && description && (
                    <div className={classes.contentWrapper}>
                      <Typography
                        variant="h6"
                        className={classes.subHeading}
                        data-test-id={"sub-heading"}
                      >
                        {description.trim()}
                      </Typography>
                    </div>
                  )}
                  <div
                    className={classes.contentWrapper}
                    style={{
                      display: !hide_sign_up ? "block" : "none",
                    }}
                  >
                    <Button
                      variant="contained"
                      className={classes.signUpButton}
                      onClick={() => this.clickSignUpButton()}
                      data-test-id={"signup-button"}
                      endIcon={isSmallScreen ? undefined : <CallMadeIcon />}
                    >
                      {isSmallScreen
                        ? configJSON.signUpButton + "!"
                        : configJSON.signUpButton}
                    </Button>
                  </div>
                  <div className={classes.contentWrapper}>
                    <Grid container>
                      <Grid
                        item
                        style={{
                          display: !hide_instagram_url ? "block" : "none",
                        }}
                      >
                        <Typography
                          className={classes.socialStyle}
                          onClick={() => this.clickInstaUrl()}
                        >
                          INSTAGRAM
                        </Typography>
                      </Grid>
                      <Grid
                        style={{
                          display: !hide_instagram_url ? "block" : "none",
                        }}
                      >
                        <span className={classes.separator}>|</span>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: !hide_facebook_url ? "block" : "none",
                        }}
                      >
                        <Typography
                          className={classes.socialStyle}
                          onClick={() => this.clickFBUrl()}
                        >
                          FACEBOOK
                        </Typography>
                      </Grid>
                      <Grid
                        style={{
                          display: !hide_facebook_url ? "block" : "none",
                        }}
                      >
                        <span className={classes.separator}>|</span>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: !hide_tiktok_url ? "block" : "none",
                        }}
                      >
                        <Typography
                          className={classes.socialStyle}
                          onClick={() => this.clickTikTokUrl()}
                        >
                          TIKTOK
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>

                  <div
                    className={classes.contentWrapper}
                    style={{
                      display: !hide_email ? "block" : "none",
                    }}
                  >
                    <Grid container direction="row" alignItems="center">
                      <div className={classes.circle}>
                        <img
                          className={classes.iconImg}
                          src={emailIcon}
                          alt={configJSON.emailIcon}
                        />
                      </div>

                      <span className={classes.separator}>
                        <Typography>
                          <a
                            className={classes.emailLink}
                            href={`mailto:${email}`}
                          >
                            {email}
                          </a>
                        </Typography>
                      </span>
                    </Grid>
                  </div>

                  <div
                    className={classes.contentWrapper}
                    style={{
                      display: !hide_address ? "block" : "none",
                    }}
                  >
                    <Grid container direction="row" alignItems="center">
                      \{" "}
                      <img
                        className={classes.iconLocationImg}
                        src={locationIcon}
                        alt={configJSON.locationIcon}
                      />
                      <span className={classes.separator}>
                        <Typography>{address}</Typography>
                      </span>
                    </Grid>
                  </div>

                  <div
                    className={classes.contentWrapper}
                    
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={6}
                        className={classes.googlePlayStoreGrid}
                        style={{
                          display: !hide_google_store ? "block" : "none",
                        }}
                      >
                        <Button
                          className={classes.downloadButtonStyles}
                          onClick={() => this.clickGooglePlayButton()}
                          data-test-id={"play-store-button"}
                        >
                          <img
                            className={classes.googlePlayLogoBodyStyle}
                            src={imgSkinBadgeGooglePlay}
                            alt={configJSON.googlePlayAlt}
                            data-test-id={"play-store-logo"}
                          />
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          display: !hide_apple_store ? "block" : "none",
                        }}
                      >
                        <Button
                          className={classes.downloadButtonStyles}
                          onClick={() => this.clickAppleStoreButton()}
                          data-test-id={"apple-store-button"}
                        >
                          <img
                            className={classes.appStoreLogoBodyStyle}
                            src={imgSkinBadgeAppStore}
                            alt={configJSON.appleStoreAlt}
                            data-test-id={"apple-store-logo"}
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div>
                  {!hide_youtube_url && (
                    <div
                      className={`${classes.contentWrapper} ${
                        classes.leftWrapper
                      }`}
                    >
                      <iframe
                        className={classes.videoBlock}
                        title={configJSON.playerTitle}
                        sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                        src={youtube_url}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;fullscreen;"
                        allowFullScreen
                      />
                    </div>
                  )}

                  {!hide_experience && (
                    <div
                      className={`${classes.contentWrapper} ${
                        classes.leftWrapper
                      } ${classes.hotelCardPadding}`}
                    >
                      <Card
                        variant="outlined"
                        className={classes.hotelInformationCard}
                      >
                        <CardContent key={""}>
                          <Typography
                            variant="h6"
                            className={classes.feedbackHeader}
                            data-test-id={"hotel-feedback-header"}
                          >
                            {label}
                          </Typography>
                          <Typography
                            variant="h6"
                            className={classes.mealDateAndTime}
                            data-test-id={"meal-date-and-time"}
                          >
                            {formatted_order_date}
                          </Typography>
                          <Typography
                            variant="h6"
                            className={classes.mealDetail}
                            data-test-id={"meal-detail"}
                          >
                            {`${meal_type}`} <span> &#8226;</span>{" "}
                            {order_time_formated}
                          </Typography>
                          <Box
                            sx={{
                              width: 200,
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "16px",
                            }}
                            className={classes.rating}
                          >
                            <img
                              src={forkIcon}
                              className={classes.restrauntIconStyle}
                              data-test-id={"restraunt-icon"}
                            />
                            <Box
                              sx={{ ml: "8px" }}
                              className={classes.mealName}
                              data-test-id={"meal-name"}
                            >
                              {type_of_food}
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              width: 200,
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "12px",
                            }}
                            className={classes.rating}
                          >
                            <Rating
                              name="text-feedback"
                              value={completeRating}
                              readOnly
                              precision={0.5}
                              emptyIcon={
                                <StarIcon
                                  className={classes.iconOpacityStyle}
                                  fontSize="inherit"
                                />
                              }
                            />
                            <Box
                              sx={{ ml: 2 }}
                              className={classes.ratingText}
                              data-test-id={"rating-text"}
                            >
                              {ratingDetail}
                            </Box>
                          </Box>

                          <Typography
                            variant="h6"
                            className={classes.currencyStyle}
                            data-test-id={"currency"}
                          >
                            {experience_currency()} {` ${price ? price : ""}`}
                          </Typography>
                        </CardContent>
                      </Card>
                    </div>
                  )}

                  {!hide_review_rating && (
                    <div
                      className={`${classes.contentWrapper} ${
                        classes.leftWrapper
                      }`}
                    >
                      <Card
                        variant="outlined"
                        className={classes.ratingCardStyle}
                      >
                        <CardContent>
                          <Box
                            display="flex"
                            alignItems="center"
                            sx={{ marginBottom: "18px" }}
                            className={classes.ratingHeaderSpacing}
                          >
                            <Box sx={{ marginRight: "2px" }}>
                              <Typography
                                variant="h6"
                                className={classes.ratingHeaderStyle}
                                data-test-id={"rating-header-text"}
                              >
                                {avRating}
                              </Typography>
                            </Box>
                            <Box width="50%">
                              <Typography
                                variant="h6"
                                className={classes.ratingReviewsCount}
                                data-test-id={"rating-review-count"}
                                style={{ marginLeft: "8px" }}
                              >
                                {review}
                                {" reviews"}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                marginLeft: "2px",
                                fontSize: "14px",
                                fontWeight: 700,
                                fontFamily: "Inter",
                                color: "#ffffff",
                              }}
                            >
                              <Rating
                                name="text-feedback"
                                value={avRating}
                                readOnly
                                precision={0.5}
                                emptyIcon={
                                  <StarIcon
                                    className={classes.iconOpacityFeedbackStyle}
                                    fontSize="inherit"
                                  />
                                }
                              />
                            </Box>
                          </Box>
                          {ratingData.map((value: any) => {
                            return (
                              <Grid container key={""}>
                                <Grid item xs={1}>
                                  <Box>
                                    <Typography
                                      variant="h6"
                                      className={classes.ratingTypographyStyle}
                                    >
                                      <p className={classes.ratingTypeStyle}>
                                        {value.ratingType}
                                      </p>
                                      <StarIcon
                                        fontSize="small"
                                        className={classes.starIconStyle}
                                      />
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={10}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      height: "100%",
                                      alignItems: "center",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    <LinearProgress
                                      className={classes.ratingBar}
                                      variant="determinate"
                                      value={Math.floor(value.ratingBarValue)}
                                      style={{ borderRadius: 10 }}
                                    />
                                  </Box>
                                </Grid>
                                <Grid item xs={1}>
                                  <Box
                                    sx={{
                                      marginLeft: "8px",
                                      fontSize: "14px",
                                      fontWeight: 700,
                                      fontFamily: "Inter",
                                      textAlign: "right",
                                      color: "#FFFFFF",
                                      height: "100%",
                                    }}
                                  >
                                    <Typography>
                                      {value.ratingCounts}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            );
                          })}
                        </CardContent>
                      </Card>
                    </div>
                  )}
                </div>
              </Container>
            </div>
            <div className={classes.bottomLinksContainer}>
              <Link
                to="/TermsConditions"
                data-test-id={"app-termsconditions"}
                className={classes.link}
                onClick={() => this.navigateToAppTermsCondsDetail()}
              >
                App T&Cs
              </Link>
              <span className={classes.separator}>|</span>
              <Link
                style={{ marginLeft: "6px" }}
                to="/HostTermsConditions"
                data-test-id={"host-termsconditions"}
                className={classes.link}
                onClick={() => this.navigateToTermsCondsHost()}
              >
                Hosting T&Cs
              </Link>
              <span className={classes.separator}>|</span>
              <span style={{ marginLeft: "6px" }} className={classes.copyright}>
                © Dish UP Online
              </span>
            </div>
          </div>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const styles: any = (theme: any) => ({
  toolBarHeightStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    height: "90px",
    [theme.breakpoints.down("sm", "xs")]: {
      minHeight: "90px",
      flexDirection: "column",
      justifyContent: "center",
      margin: "1rem 0 0",
    },
  },
  dishUpLogoStyle: {
    width: "224px",
    height: "54px",
    left: "140px",
    top: "18px",
    position: "absolute" as const,
    [theme.breakpoints.down("sm", "xs")]: {
      height: "27px",
      width: "113px",
      marginBottom: "5px",
      position: "absolute" as const,
      transform: "translate(-50%, -50%)",
      left: "50%",
      top: "50%",
    },
  },
  googlePlayLogoBodyStyle: {
    textDecoration: "none" as const,
    [theme.breakpoints.down("sm", "xs")]: {
      height: "43px",
      width: "143px",
      marginBottom: "5px",
      display: "block" as const,
    },
  },
  appStoreLogoBodyStyle: {
    textDecoration: "none" as const,
    [theme.breakpoints.down("sm", "xs")]: {
      height: "41.12px",
      width: "125.15px",
      marginBottom: "5px",
      display: "block" as const,
      float: "none" as const,
    },
  },
  containerWrapper: {
    display: "flex",
    width: "calc(100vw - 170px)",
    justifyContent: "space-between",
    margin: "12rem auto 2rem auto",
    "@media(max-width: 1920px)": {
      marginTop: "6rem",
    },
    "@media(max-width: 1368px)": {
      marginTop: "2rem",
    },
    "@media(max-width: 600px)": {
      width: "100%",
    },
    "&.MuiContainer-root": {
      paddingLeft: "1px",
      paddingRight: "1px",
      maxWidth: "100%",
    },
    "@media screen and (max-width: 1600px) and (max-height: 900px)": {
      marginTop: "2.5rem",
    },
    [theme.breakpoints.down("sm", "xs")]: {
      flexDirection: "column",
      margin: "3rem auto",
      "&.MuiContainer-root": {
        marginTop: "180px",
      },
    },
  },
  contentWrapper: {
    marginBottom: "3rem",
    "@media(max-width: 1368px)": {
      marginBottom: "2rem",
    },
    "@media screen and (max-width: 1440px) and (max-height: 810px)": {
      marginBottom: "1.5rem",
    },
  },
  leftWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  mainHeading: {
    width: "450px",
    fontFamily: "New York",
    fontStyle: "normal",
    fontWeight: 568,
    fontSize: "64px",
    lineHeight: "76px",
    letterSpacing: "-0.408px",
    color: "#FFFFFF",
    [theme.breakpoints.down("sm", "xs")]: {
      width: "250px",
      fontSize: "40px",
      lineHeight: "43px",
    },
  },
  subHeading: {
    width: "413px",
    fontFamily: "SF Pro",
    fontStyle: "normal",
    fontWeight: 510,
    fontSize: "20px",
    lineHeight: "23.87px",
    color: "#FFFFFF",
    letterSpacing: "0.4px",
    [theme.breakpoints.down("sm", "xs")]: {
      width: "225px",
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "16.71px",
    },
  },
  socialStyle: {
    heigh: "16px",
    color: "#FFFFFF",
    lineHeight: "19.36px",
    fontSize: "12px",
    fontFamily: "Inter",
    "&:hover": {
      textDecoration: "underline 2px",
      cursor: "pointer",
    },
  },

  circle: {
    display: "flex",
    width: "35px",
    height: "35px",
    borderRadius: "50%",
    border: "1px solid #FFFFFF",
  },

  iconImg: {
    margin: "auto",
    width: "15px",
    height: "15px",
  },

  iconLocationImg: {
    width: "35px",
    height: "35px",
    marginLeft: "-2px",
    borderRadius: "50%",
    border: "1px solid #FFFFFF",
  },

  emailLink: {
    color: "#FFFFFF",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline 2px",
      cursor: "pointer",
    },
  },

  signUpButton: {
    width: "165px",
    height: "50px",
    fontFamily: "SF Pro",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#000000",
    backgroundColor: "#F3E344",
    textTransform: "none" as const,
    borderRadius: "50px",
    padding: "0px 16px",
    [theme.breakpoints.down("sm", "xs")]: {
      height: "42px",
      width: "149.9px",
      borderRadius: "36.2069px",
      whiteSpace: "nowrap" as const,
      "& .MuiButton-endIcon": {
        marginLeft: "0px",
      },
    },
    "@media(max-width: 500px)": {
      fontSize: "17px",
    },
  },
  videoBlock: {
    width: "452.41px",
    height: "235px",
    boxShadow: "0.45rem 0.45rem #F3E344",
    [theme.breakpoints.down("sm", "xs")]: {
      width: "100%",
      boxShadow: "none",
      marginLeft: "-14px",
      marginRight: "-5px",
      border: "5px solid white",
    },
    "@media(max-width: 1368px)": {
      width: "428.41px",
      height: "208px",
    },
  },
  hotelInformationCard: {
    width: "327px",
    radius: "12px",
    borderRadius: "12px",
    border: "0.2px solid #dddddd7d",
    backgroundColor: "rgba(255, 255, 255, 0.12)",
    backdropFilter: " blur(5px)",
    height: "222px",
    [theme.breakpoints.down("sm", "xs")]: {
      width: "100%",
      backgroundColor: "rgba(255, 255, 255, 0.12)",
      backdropFilter: " blur(5px)",
      left: "5%",
      right: "5%",
    },
  },
  hotelCardPadding: {
    [theme.breakpoints.down("sm", "xs")]: {
      padding: "0px 10px 0px 10px",
    },
  },
  ratingCardStyle: {
    opacity: 1.0,
    width: "375px",
    backgroundColor: "transparent" as const,
    shadowOpacity: 0,
    borderRadius: "12px",
    border: "none" as const,
    [theme.breakpoints.down("sm", "xs")]: {
      width: "100%",
    },
    "@media(max-width: 1368px)": {
      marginTop: "-35px",
    },
    "@media screen and (max-width: 1440px) and (max-height: 810px)": {
      marginTop: "-30px",
    },
  },
  ratingHeaderSpacing: {
    "@media(max-width: 1368px)": {
      marginBottom: "0px",
    },
  },
  feedbackHeader: {
    color: "#ffffff",
    fontFamily: "AirbnbCereal_W_Bd",
    fontStyle: "normal" as const,
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "23px",
    marginBottom: "16px",
  },
  mealDetail: {
    color: "#cbd5e1",
    fontFamily: "SF Pro",
    fontStyle: "normal" as const,
    fontWeight: 590,
    fontSize: "13px",
    lineHeight: "14px",
    textTransform: "uppercase" as const,
    marginBottom: "16px",
    [theme.breakpoints.down("sm", "xs")]: {
      fontSize: "14px",
    },
  },
  mealDateAndTime: {
    width: "146px",
    height: "20px",
    fontFamily: "SF Pro",
    fontStyle: "normal" as const,
    fontWeight: 510,
    fontSize: "13px",
    color: "#FFFFFF",
    backgroundColor: "#6699CC",
    borderRadius: "15px",
    textAlign: "center" as const,
    marginBottom: "16px",
  },
  mealName: {
    color: "#ffffff",
    fontFamily: "SF Pro",
    fontStyle: "normal" as const,
    fontWeight: 590,
    fontSize: "14px",
    textTransform: "none" as const,
    [theme.breakpoints.down("sm", "xs")]: {
      fontWeight: 600,
    },
  },
  ratingText: {
    fontFamily: "SF Pro",
    fontStyle: "normal" as const,
    fontWeight: 590,
    fontSize: "14px",
    color: "#ffffff",
  },
  ratingHeaderStyle: {
    height: "44px",
    fontFamily: "Inter",
    fontStyle: "normal" as const,
    fontWeight: 700,
    fontSize: "36px",
    lineHeight: "44px",
    letterSpacing: "-0.36px",
    color: "#FFFFFF",
  },
  ratingReviewsCount: {
    width: "100px",
    height: "33px",
    fontFamily: "Inter",
    fontStyle: "normal" as const,
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "45px",
    color: "#FFFFFF",
    flex: "none" as const,
    order: 1,
    flexGrow: 0,
  },
  mainContainer: {
    overflowX: "hidden",
    width: "100%",
    minHeight: "calc(100vh - 90px)",
    background: `linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0), rgba(0,0,0,1)), url(${imgBackground})`,
    backgroundSize: "cover" as const,
    backgroundRepeat: "no-repeat" as const,
    [theme.breakpoints.down("sm", "xs")]: {
      background: `linear-gradient(to left, transparent, rgba(0,0,0,1)), url(${imgBackgroundMobile})`,
      backgroundSize: "cover" as const,
      backgroundPosition: "center center",
    },
  },
  parentDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    minHeight: "calc(100vh - 90px)",
  },
  childDiv: {
    paddingLeft: "25px",
  },
  restrauntIconStyle: {
    fontSize: "20px",
    color: "#FFFFFF",
    fontWeight: 510,
  },
  iconOpacityStyle: {
    opacity: 1,
    color: "#94a3b8",
  },
  iconOpacityFeedbackStyle: {
    opacity: 1,
    color: "#e2e8f0",
  },
  ratingTypeStyle: {
    margin: "0px",
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "Inter",
    color: "#ffffff",
  },
  ratingTypographyStyle: {
    display: "flex" as const,
    alignItems: "center" as const,
  },
  starIconStyle: {
    margin: "0px 0px 1px 0px",
    padding: "0px 3px 0px 3px",
    color: "#ffffff",
    fontSize: "0.80rem",
  },
  ratingBar: {
    width: "100%",
    height: "8px",
    overflow: "hidden",
    position: "relative",
    backgroundColor: "#FFFFFF",
    "& .MuiLinearProgress-bar1Determinate": {
      backgroundColor: "#F59E0B",
      borderRadius: "40px",
    },
  },
  currencyStyle: {
    color: "#FFFFFF",
    fontFamily: "SF Pro",
    fontStyle: "normal" as const,
    fontWeight: 590,
    fontSize: "16px",
    lineHeight: "19.09px",
    textTransform: "none" as const,
    width: "219.12px",
    height: "13.56px",
    [theme.breakpoints.down("sm", "xs")]: {
      fontSize: "18px",
    },
  },
  googlePlayStoreGrid: {
    maxWidth: "35%",
    marginLeft: "-10px",
    marginRight: "50px",
  },
  downloadButtonStyles: {
    paddingTop: "0px",
  },
  downloadGoogleNavBarStyle: {
    paddingTop: "0px",
    marginRight: "10px",
  },
  showNhideAppStore: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  bottomLinksContainer: {
    bottom: 0,
    left: 0,
    paddingLeft: "120px",
    paddingBottom: "2%",
    width: "100%",
    padding: "10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      padding: "5px",
      paddingBottom: "3%",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      height: "auto",
      position: "static",
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  link: {
    color: "#fff",
    textDecoration: "none",
    marginRight: "10px",
    fontStyle: "normal",
    fontFamily: "Inter",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
    transition: "text-decoration 0.3s",
    "&:hover": {
      textDecoration: "underline 2px",
      cursor: "pointer",
      textUnderlineOffset: "8px",
      fontFamily: "Inter",
      lineHeight: "24px",
      fontWeight: 600,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0px",
      fontSize: "14px",
      fontWeight: 600,
    },
  },
  separator: {
    color: "#fff",
    margin: "0 10px",
    [theme.breakpoints.down("sm")]: {
      margin: "0 5px",
    },
  },
  copyright: {
    color: "#fff",
    textDecoration: "none",
    marginRight: "10px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px",
      fontSize: "14px",
      fontWeight: 600,
    },
  },
});
export default withStyles(styles)(withWidth()(LandingPage));
// Customizable Area End
