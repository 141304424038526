import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any;
  reviewAndRating: any;
  avarageRating: any;
  rating: any;
  linkName: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      data: {},
      reviewAndRating: {},
      avarageRating: 0,
      rating: 0,
      linkName: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && apiRequestCallId && responseJson !== undefined) {
        if (apiRequestCallId === this.getAllData) {
          this.setState({
            data: responseJson.LandingPage,
            avarageRating: responseJson.LandingPage.average_rating,
            rating: responseJson.LandingPage.rating,
          });
        }

        if (apiRequestCallId === this.getReviewRatingData) {
          this.setState({
            reviewAndRating: responseJson.review,
          });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): any {
    this.getLandingPageData();
    this.reviewAndExperienceData(1);
  }
  getAllData = "";
  async getLandingPageData() {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.httpLandingPageRoute
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getReviewRatingData = "";

  async reviewAndExperienceData(id: any) {
    const header = {
      "Content-Type": "application/json",
      token:
        "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NjQ5LCJleHAiOjE2OTA0NDAxMjl9.ssvpdKfI74o-LNX3PRuRV6uTzTwbTIkUcQ_L4bSfISsxxo06LjOHdcdltICwJLviNTArE-84d6jSVTv2_Vyj4g",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getReviewRatingData = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.reviewRoute}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  clickGooglePlayButton() {
    if (window.location.href !== undefined && this.state.data.google_store) {
      window.open(this.state.data.google_store, "_blank");
    }
  }

  clickAppleStoreButton() {
    if (window.location.href !== undefined && this.state.data.apple_store) {
      window.open(this.state.data.apple_store, "_blank");
    }
  }

  clickSignUpButton() {
    if (window.location.href !== undefined && this.state.data.sign_up) {
      window.open(this.state.data.sign_up, "_blank");
    }
  }

  clickInstaUrl() {
    if (window.location.href !== undefined && this.state.data.instagram_url) {
      window.open(this.state.data.instagram_url, "_blank");
    }
  }

  clickFBUrl() {
    if (window.location.href !== undefined && this.state.data.facebook_url) {
      window.open(this.state.data.facebook_url, "_blank");
    }
  }

  clickTikTokUrl() {
    if (window.location.href !== undefined && this.state.data.tiktok_url) {
      window.open(this.state.data.tiktok_url, "_blank");
    }
  }

  navigateToAppTermsCondsDetail = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TermsConditionsDetail"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), message);
    this.send(message);
  };

  navigateToTermsCondsHost = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TermsConditionsEdit"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), message);
    this.send(message);
  };
  // Customizable Area End
}
